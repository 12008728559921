.main-header {
  padding: 15px 0;
  width: 100%;
  z-index: 9999;
  position: absolute;
  top: 0;
}
.flex-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left-content {
  display: flex;
  gap: 50px;
  align-items: center;
}

.nav-link li a {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  text-decoration: none;
  color: #000000;
  transition: all 0.3s ease;
}
.nav-link li a:hover,
.active {
  color: #0083fd !important;
  transition: all 0.2s ease;
}

.nav-link {
  display: flex;
  gap: 30px;
}
.right-content {
  display: flex;
  gap: 10px;
}
.mobile-only {
  display: none;
}
.sticky-header {
  position: fixed;
  z-index: 99999;
  animation: headerdown 0.5s ease forwards;
  top: 0;
  background-color: white;
}
@media (max-width: 940px) {
  .right-content {
    display: none;
  }
  .mobile-only {
    display: block;
  }
  .mbl-bar svg {
    width: 1.6rem;
  }
  .links {
    top: 76px;
    position: absolute;
    width: 100%;
    background: rgb(255, 255, 255);
    left: 0;
    transition: all 0.3s ease;
    overflow: hidden;
    height: 0;
  }
  .nav-link {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .active-nav {
    height: 380px;
    transition: all 0.3s ease;
  }
}
@keyframes headerdown {
  from {
    box-shadow: none;
    top: -100px;
  }
  to {
    top: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
