.howPlay {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.play-heading {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  background: linear-gradient(134.14deg, #0083fd 0%, #7fe7ff 98.51%);
  border-radius: 40px;
  padding: 15px 130px;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  width: fit-content;
  margin-bottom: 30px;
  color: #ffffff;
}
.flex-play {
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 100px;
  margin-top: 60px;
  align-items: center;
}
.mt-2 {
  margin-top: 20px;
}
.extra-gap {
  gap: 80px !important;
}
.extra-gap img {
  margin-left: 100px;
}
.howPlay .instructions {
  font-size: 20px;
}
.howPlay .steps.roadmap-card {
  height: 100%;
  width: 394px;
  position: relative;
}
.howPlay .steps.roadmap-card.st2 {
  width: 450px;
}
.howPlay .steps.roadmap-card.st2.st3 {
  width: 480px;
}
.howPlay .st2 .roadmap-list p {
  padding-right: 150px !important;
}
.howPlay .st3 .roadmap-list p {
  padding-right: 180px !important;
}
.howPlay .st2 .roadmap-list .theme-color {
  padding-right: 100px;
  font-size: 15px;
  text-align: left;
}
.howPlay .steps img {
  top: -34px;
  bottom: 0;
  position: absolute;
  margin-left: 14rem;
  margin-top: -6rem;
}
.howPlay .st1 img {
  max-width: 20rem;
}
.howPlay .st2 img {
  right: -100px;
}
.howPlay .st3 img {
  right: -65px !important;
}
.howPlay .steps.roadmap-card h3 {
  text-align: left;
  background: #7fe7ff;
  color: black;
}
.howPlay .steps .roadmap-list p {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  padding-right: 150px;
  line-height: 26px;

  color: #000000;
}

@media (max-width: 1300px) {
  .howPlay .side-img {
    display: none;
  }
  .howPlay .flex-play {
    margin-top: 150px;
  }
  .howPlay .play-heading {
    padding: 15px 50px;
  }
  .howPlay .steps img {
    width: 220px;
    right: -60px;
  }
  /* .steps.roadmap-card {
    height: 180px;
  } */
  .howPlay .st2 img {
    width: 185px;
  }
  .howPlay .st2 .roadmap-list p {
    padding-right: 90px !important;
  }

  .howPlay .roadmap-card {
    height: auto !important;
  }
  .howPlay .roadmap-list {
    height: 150px !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .st3 {
    margin-bottom: 100px;
  }
  .howPlay .steps .roadmap-list p {
    padding-right: 95px;
  }
  .howPlay .roadmap-card {
    width: 450px !important;
  }
}
@media (max-width: 600px) {
  .howPlay .roadmap-card {
    width: 80% !important;
  }
}
@media (max-width: 500px) {
  .howPlay .roadmap-card img {
    display: none !important;
  }
  .howPlay .roadmap-list {
    height: auto !important;
  }
  .howPlay .flex-play {
    margin-top: 40px;
  }
  .howPlay .roadmap-card {
    margin-bottom: 0;
  }
  .howPlay .roadmap-card {
    width: 100% !important;
  }
  .howPlay .roadmap-list p,
  .howPlay .st2 .roadmap-list p {
    padding: 0 !important;
  }
  .flex-play img {
    width: 100%;
    margin-left: 40px;
  }
  .play-heading {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
}
