.team-bg {
  position: relative;
}
.team-bg img {
  border-radius: 30px;
  width: 100%;
}
.team-bg h1 {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  position: absolute;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  margin: auto;
  right: 0;
  bottom: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  line-height: 50px;
}
.team-flex {
  margin-top: 80px;
  display: flex;
  justify-content: space-evenly;
}
.single-team {
  width: 20%;
}

.single-team img {
  width: 100%;
  border-radius: 20px;
}

.single-team p {
  text-align: center;
  padding: 10px;

  font-weight: 400;
  font-size: 18px;
  background-color: white;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 8px 31px rgba(171, 171, 171, 0.2);

  text-align: center;

  color: #000000;
}
.team-bg {
  overflow: hidden;
}
.team-flex {
  flex-wrap: wrap;
}
@media (max-width: 940px) {
  .team-bg img {
    border-radius: 0;
    width: auto;
  }
  .team-flex {
    row-gap: 30px;
  }
  .single-team {
    width: 45%;
  }
  .single-team p {
    padding-inline: 0px;
  }
}

@media (max-width: 500px) {
  .single-team {
    width: 46%;
  }
  .team-bg h1 {
    font-size: 15px;
    line-height: 25px;
  }
}
