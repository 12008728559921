.sub-section {
  padding: 70px 0;
  text-align: center;
  background: linear-gradient(134.14deg, #0083fd 0%, #7fe7ff 98.51%);
}
.sub-para {
  margin: 20px 0 30px 0;
  color: #f8f6f6;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}
.sub-section .cotainer-width h1 {
}
.sub-head {
  font-weight: 500;
  font-size: 26px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
}

.email-container {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.email-container input {
  width: 300px;
  padding: 15px 40px 15px 15px;

  background: #ffffff;
  border-radius: 10px;
  outline: none;
  border: none;
}
.sub-btn {
  position: absolute;
  /* padding: 0; */
  background: transparent;
  border: none;
  right: 13px;
  top: 12px;
  cursor: pointer;
}
.footer-nav {
  padding: 60px 0;
}
.footer-nav {
  display: flex;
  justify-content: space-between;
}
.left-link {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
}
.left-link h1,
.news-letter h1 {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;

  color: #000000;
}
.left-link ul {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.left-link ul li a,
.descs li {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  text-decoration: none;
  color: #000000;
}
.hidden {
  visibility: hidden;
}
.footer-nav {
  width: 38%;
}
.footer-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.justify-start {
  justify-content: start;
}
.two {
  width: 45%;
}
.news-letter {
  margin-top: 60px;
  margin-bottom: 60px;
}
.news-letter h1 {
  text-align: center;
  margin-bottom: 20px;
}
.news-letter .email-container input {
  border: 1px solid black;
}
.footer-line {
  border: 1px solid #e9e9e9;
}
.country-tags {
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
}
.left-tag h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 13px;

  color: #000000;
}
.drop-down-price {
  width: 200px;
  padding: 15px;
  box-sizing: border-box;
  font-weight: 400;
  cursor: pointer;
  font-size: 12px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 10px;
  color: #000000;
}
.pay-opt {
  display: flex;
  gap: 8px;
  margin-bottom: 22px;
}
.about p {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  color: #000000;
}
.footer-flex {
  box-sizing: border-box;
}
.left-tag {
  padding-left: 20px;
  padding-right: 20px;
}

.social-links {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  max-width: 45rem;
  flex-wrap: wrap;
  margin: auto;
  max-width: 60rem;;
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background: white;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem;
  width: 10rem;
  height: 9rem;
}

.social-eth-address {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background: white;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem;
  width: 45rem;
  height: 7rem;
}

.sub-social {
  margin: auto;
  margin-top: 0.6rem;
  color: #000000;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  /* identical to box height */

  text-align: center;

  color: #070707;
}

.sub-social-eth {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  margin: auto;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  gap: 1rem;
  color: #070707;
}

.social-links img {
  max-width: 3rem;
}

.social-links a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .social-links {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }

  
  .social-eth-address {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    background: white;
    border-radius: 1rem;
    padding: 1rem;
    margin: 1rem;
    max-width: 19rem;
    height: 7rem;
  }

  .sub-social-eth {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    margin: auto;
    color: #000000;
    font-family: "Comfortaa";
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem;
    /* identical to box height */
  
    text-align: center;
  
    color: #070707;
  }


  .footer-flex {
    flex-direction: column;
  }
  .footer-nav {
    /* width: 100%; */
  }
  .left-link ul {
    width: 200px;
  }
  .two .left-link ul {
    width: 100%;
  }
  .two {
    width: 100%;
    padding-top: 0;
  }
  .country-tags {
    flex-direction: column;
    gap: 30px;
  }
}
@media (max-width: 600px) {
  .pay-opt {
    flex-wrap: wrap;
  }
  .sub-head {
    font-size: 20px;
  }
  .sub-para {
    font-size: 15px;
  }
  .news-letter {
    margin-top: 0;
  }
}
@media (max-width: 400px) {
  .email-container input {
    width: 250px;
  }
  .footer-nav {
    flex-direction: column;
  }
  .left-link .hidden {
    display: none;
  }
  .footer-nav {
    gap: 20px;
    width: 100%;
  }
}
