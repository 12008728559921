.platfrom-heading {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  color: #000000;
}
.text-center {
  text-align: center;
}

.single-social {
  text-decoration: none;
  color: inherit; 
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  background: transparent; 
  border-radius: 10px;
  padding: 20px; 
  margin: 0;
  border: none;
  overflow: hidden; 
}

.single-social img {
  width: 100%; 
  height: auto; 
}


@media (max-width: 1300px) {
  .social-flex {
    gap: 10px; 
  }
  .single-social {
    width: calc(40% - 40px); 
  }
}

@media (max-width: 768px) {
  .platfrom-heading {
    font-size: 25px;
  }
  .single-social {
    width: calc(46% - 40px); 
  }
}

@media (max-width: 420px) {
  .single-social {
    width: calc(100% - 40px);
  }
}