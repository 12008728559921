.welcome {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  margin: 40px 0;
  text-align: center;
}
.welcome-bg {
  /* width: 100%; */
  margin: 0 auto;
  text-align: center;
  height: 470px;
  margin-bottom: 40px;
}
.instructions {
  text-align: center;

  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 8px 0;

  text-align: center;

  color: #000000;
}
.video {
  margin: 0 auto;
  width: auto;
  text-align: center;
}
.iframe {
  height: 470px;
  width: 90%;
  margin: 0 auto;
}
.last-inst {
  margin-top: 50px;
}
@media (max-width: 768px) {
  .last-inst {
    margin-top: 40px;
  }
  .welcome {
    font-size: 20px;
    margin: 20px 0;
  }

  .video {
    /* height: 400px; */
  }
  .welcome-bg {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    height: auto;
    margin-bottom: 40px;
  }
}
