.reviews-slide {
  margin-top: 80px;
}
.single-review {
  background: #ffffff;
  box-shadow: 0px 8px 31px rgba(171, 171, 171, 0.2);
  border-radius: 30px;
  padding: 30px;
  box-sizing: border-box;
  /* width: 394px; */
  height: 250px;
  margin: 0 10px;
}
.user-detail {
  display: flex;
  gap: 20px;
  align-items: center;
}
.nm {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;

  color: #000000;
}

.duty {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 500;
  font-size: 14.5px;
  margin-top: 10px;
}
.review {
  margin-top: 20px;
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 14.5px;

  color: #000000;
}
.slick-slide {
  /* margin: 0 10px; */
}
.slick-slide {
  /* height: 140px !important; */
}
