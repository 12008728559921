
.loading-bar-container {
    width: 100%; 
    background-color: #d3d3d3; 
    border-radius: 15px;
    margin: 20px 0; 
  }
  
  .loading-bar {
    height: 20px; 
    background-color: #0083FD; 
    border-radius: 15px; 
    transition: width 0.5s ease-in-out; 
  }
  .blue-text {
    color: #007bff;
  }
  .Uniswap {
    width: 100%; 
    display: flex;
    justify-content: center; 
    align-items: center;
    margin-top: 4rem;
  }
  

.num-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
}
.number-flex {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 35px;
  gap: 15px;
  width: max-content;
  padding-bottom: 30px;
  margin-inline: auto;
  border-radius: 30px;
}
.border-line-light {
  width: 100%;
  height: 0px;
  border-radius: 21px;
  border: 16px solid #6db9ff;
}
.border-line {
  height: 10px;
  border-radius: 30px;
  box-sizing: border-box;
  border: 16px solid #0083fd;
  margin-top: -1rem;
  margin-left: -1rem;
}
.single-number {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  background-image: url("../../assets/img/numberbg.svg");
  line-height: 49px;
  background-size: cover;
  background-position: center;
  text-align: center;

  width: 49px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  color: #ffffff;
}
.num-sec {
  display: flex;
  gap: 15px;
}
.coma {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 1rem;

  color: #000000;
}
.sale-remaining {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin-top: 30px;

  text-align: center;

  color: #000000;
}
@media (max-width: 940px) {
  .single-number {
    font-size: 30px;
    width: 38px;
    height: 60px;
  }
}
@media (max-width: 768px) {
  .single-number {
    font-size: 21px;
    width: 26px;
    height: 41px;
  }
  .number-flex,
  .num-sec {
    gap: 8px;
  }
  .num-main p {
    text-align: center;
  }
}
@media (max-width: 500px) {
  .single-number {
    font-size: 16px;
    width: 18px;
    height: 28px;
  }
  .number-flex,
  .num-sec {
    gap: 5px;
  }
  .coma {
    font-size: 22px;
    margin-top: 0.1rem;
  }
  /* .number-flex {
    width: 330px;
    border-radius: 0;
    overflow: scroll;
  } */
  .sale-remaining {
    font-size: 16px;
  }
  .platfrom-heading {
    font-size: 22px;
  }
}
@media (max-width: 350px) {
  /* .number-flex {
    width: 280px;
  } */
}
